import React, { useEffect, useRef, useState } from 'react';
import './File_Editor_Page.css';
import BackGround from '../Home_page/BackGround';
import * as pi from 'react-icons/pi';
import '../Personal_page/Event/Event.css';
import blank_iphone from '../../images/phone.png';
import * as ci from 'react-icons/ci';
import * as bi from 'react-icons/bi';
import * as tb from 'react-icons/tb';
import * as Iconset from 'react-icons/hi2';

import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';

import { get_event_by_id } from '../../firebase';
import Small_Phone from '../Add_Event_Page/Phone/Small_Phone';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { calculateScaledFontSize } from '../../Util/helpers';
import html2canvas from 'html2canvas'; // Import html2canvas
import QRCode from 'react-qr-code';
import { FILES } from './data';
import Poster from './Poster';
import { BeatLoader } from 'react-spinners';

const File_Editor_Page = () => {
  const location = useLocation();
  const { event_id } = useParams();
  const [event, set_event_data] = useState();
  const [phone_img, set_phone_img] = useState();
  const [qr_img, set_qr_img] = useState();
  const history = useHistory();
  const iphone_ref = useRef();
  const qr_ref = useRef();
  const [is_dark_color, set_is_dark_color] = useState(false);
  useEffect(() => {
    get_event_by_id(event_id, (data) => {
      set_event_data(data);
      const [r, g, b] = data.button_color
        .match(/\w\w/g)
        .map((hex) => parseInt(hex, 16));
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      if (brightness < 128) set_is_dark_color(true);
      else set_is_dark_color(false);
    });
  }, []);
  useEffect(() => {
    handleCreateImages();
  }, [event]);
  const handleCreateImages = () => {
    if (iphone_ref.current && qr_ref.current) {
      setTimeout(() => {
        html2canvas(iphone_ref.current, {
          scale: 10,

          useCORS: true,
          backgroundColor: null,
        }).then((canvas) => {
          const imageData = canvas.toDataURL('image/png'); // Capture the image as base64
          set_phone_img(imageData);
        });
        html2canvas(qr_ref.current, {
          useCORS: true,
          backgroundColor: null,
        }).then((canvas) => {
          const imageData = canvas.toDataURL('image/png'); // Capture the image as base64
          set_qr_img(imageData);
        });
      }, 1000);
    }
  };

  const createImage = (objectRef, fileName) => {
    if (objectRef.current) {
      html2canvas(objectRef.current, {
        scale: 10,

        useCORS: true,
        backgroundColor: null,
      }).then((canvas) => {
        const imageData = canvas.toDataURL('image/png'); // Capture the image as base64
        const downloadLink = document.createElement('a');
        downloadLink.href = imageData;
        downloadLink.download = `${fileName}.png`; // Specify the filename
        // Trigger a click on the anchor element to start the download
        downloadLink.click();
      });
    }
  };
  const DownloadButton = ({ objectRef, fileName }) => {
    return (
      <div
        style={{
          flexDirection: 'row-reverse',
          fontSize: 'var(--large)',
          alignItems: 'center',
          gap: '10px',
        }}
        className={'admin_download_icon_container'}
        onClick={() => {
          createImage(objectRef, fileName);
        }}
      >
        <bi.BiDownload></bi.BiDownload>
        <span style={{ fontSize: 'var(--medium)' }}>הורדה</span>
      </div>
    );
  };
  const PhoneImage = ({ width }) => {
    return (
      <div className='phoneImageComponent'>
        <img src={phone_img} style={{ width: width }}></img>
      </div>
    );
  };
  return (
    <div className='filesPage'>
      <BackGround></BackGround>

      <div
        onClick={() =>
          history.push({
            pathname: `/Personal_page`,
            state: { user_id: location.state.user_id },
          })
        }
        style={{
          position: 'fixed',
          top: '15px',
          left: '15px',
          fontSize: '35px',

          zIndex: '3',
        }}
      >
        <Iconset.HiOutlineArrowLeft className='close_icon'></Iconset.HiOutlineArrowLeft>{' '}
      </div>
      <header>
        <span style={{ fontSize: 'var(--large)' }}> העיצובים שלכם</span>
        <span style={{ fontSize: 'var(--medium)' }}>אלמנטים לעיצוב אישי</span>
      </header>
      <main style={{}}>
        {event && (
          <div
            style={{
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'space-around',
              width: '100vw',
            }}
          >
            <div className='object_stack'>
              <div ref={qr_ref}>
                {' '}
                <QRCode
                  value={`https://our-moments.web.app/event/${event_id}`}
                  bgColor='#FFFFFF00'
                  style={{
                    width: '30vw',
                    height: '30vw',
                    maxHeight: '150px',
                    maxWidth: '150px',
                  }}
                ></QRCode>
              </div>
              <DownloadButton
                objectRef={qr_ref}
                fileName={'qr_code'}
              ></DownloadButton>
            </div>
            <div className='object_stack'>
              <div
                style={{
                  scale: '1',
                  position: 'relative',
                  marginTop: '10vh',
                }}
              >
                <Small_Phone
                  page={1}
                  is_en={event.is_en}
                  button_color={event.button_color}
                  event_name={event.event_name}
                  date={event.date}
                  is_dark_color={is_dark_color}
                  photos={event.config_data.photos}
                  handle_bg_img={() => {}}
                  handle_event={() => {}}
                  is_set_img={false}
                  image_url={event.img_url}
                  phoneRef={iphone_ref}
                  dont_show_draggers={true}
                  isFilePage={true}
                  handleCreateImages={handleCreateImages}
                  lastModify={event?.lastModify}
                ></Small_Phone>
              </div>
              <div style={{ marginTop: '8vh' }}>
                <DownloadButton
                  objectRef={iphone_ref}
                  fileName={'phone_image'}
                ></DownloadButton>
              </div>
            </div>
          </div>
        )}
        {phone_img ? (
          <div className='posterCovers_section'>
            <header>
              <span style={{ fontSize: 'var(--large)' }}> העיצובים שלכם</span>
              <span style={{ fontSize: 'var(--medium)' }}>
                פוסטרים בעיצוב אישי
              </span>
            </header>
            <div className='posters_section' style={{ gap: '100px' }}>
              {FILES.map((src, index) => {
                return (
                  <Poster
                    key={index}
                    index={index}
                    posterSrc={src}
                    phoneImgSrc={phone_img} // Pass phone_img as a prop
                    qrImage={qr_img}
                    event_name={event?.event_name}
                    date={event?.date}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <BeatLoader color='var(--blue)' size='5vw' />
          </div>
        )}
      </main>
    </div>
  );
};

export default File_Editor_Page;
