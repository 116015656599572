import React, { useRef, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import html2canvas from 'html2canvas';
import * as bi from 'react-icons/bi';

const Poster = ({
  posterSrc,
  phoneImgSrc,
  qrImage,
  event_name,
  date,
  index,
}) => {
  const posterRef = useRef();
  const downloadButtonRef = useRef(); // Ref for download button
  const [isLoading, setIsLoading] = useState(false);

  const createImage = (objectRef, fileName) => {
    if (objectRef.current) {
      const downloadButton = downloadButtonRef.current;
      if (downloadButton) downloadButton.style.display = 'none';

      setIsLoading(true);

      html2canvas(objectRef.current, {
        scale: 10,
        useCORS: true,
        backgroundColor: null,
      })
        .then((canvas) => {
          if (downloadButton) downloadButton.style.display = 'flex';

          // Convert canvas to Blob
          canvas.toBlob((blob) => {
            if (!blob) {
              alert('Blob creation failed. Please try again.');
              setIsLoading(false);
              return;
            }

            const url = URL.createObjectURL(blob);

            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = `${fileName}.png`;

            // Programmatically click the link
            downloadLink.click();

            // Clean up the URL.createObjectURL
            URL.revokeObjectURL(url);
            setIsLoading(false);
          }, 'image/png');
        })
        .catch(() => {
          setIsLoading(false);
          alert('Download failed. Please try again.');
        });
    }
  };
  const DownloadButton = ({ objectRef, fileName }) => {
    return (
      <div
        ref={downloadButtonRef} // Set ref for download button
        style={{
          display: 'flex', // Default display value to make it visible initially
          flexDirection: 'row-reverse',
          fontSize: 'var(--large)',
          alignItems: 'center',
          gap: '10px',

          zIndex: '9999999',
          opacity: isLoading ? '0.4' : '1',
        }}
        className={'admin_download_icon_container'}
        onClick={() => {
          if (!isLoading) {
            setIsLoading(true);
            createImage(objectRef, fileName);
          }
        }}
      >
        {isLoading ? (
          <BeatLoader color='black' size='10px' />
        ) : (
          <bi.BiDownload />
        )}
        <span style={{ fontSize: 'var(--medium)' }}>הורדה</span>
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center',
        marginRight: '-15vw',
      }}
    >
      <div className='posterCover_container' ref={posterRef}>
        <div className={`posterCover_header_1  ${event_name?.font?.name}`}>
          <span>{event_name?.name}</span>
        </div>
        <div className={`posterCover_header_2  ${date?.font?.name}`}>
          <span>{date?.name}</span>
        </div>
        <img
          src={posterSrc}
          alt='Poster'
          className='posterCover_image'
          effect='blur'
        />
        <img
          src={phoneImgSrc}
          alt='Phone Display'
          className='phone_image'
          effect='blur'
        />
        <div className='qr_image_container'>
          <img
            src={qrImage}
            alt='qr Display'
            className='qr_image'
            effect='blur'
          />
        </div>
      </div>
      <DownloadButton objectRef={posterRef} fileName={`poster${index + 1}`} />
    </div>
  );
};

export default Poster;
